import React from "react";

const Heart = ({ color = "red" }) => (
  <svg preserveAspectRatio="xMinYMin meet" shapeRendering="crispEdges" viewBox="0 0 32 32" width="100%" height="100%">
    <path fill={color}
      d="m4 0h4v2h4v2h2v4h4v-4h2v-2h4v-2h4v2h2v2h2v16h-4v4h-4v4h-4v4h-8v-4h-4v-4h-4v-4h-4v-16h2v-2h2v-2z" />
  </svg>
);

export default Heart;
