import React from "react";
import { Helmet } from "react-helmet";

import "./style.scss";

import Heart from "./heart";

class Option extends React.Component {
    constructor(props) {
        super(props)
        this.heartColor = props.heartColor || 'white';
        this.text = props.text || '';
        this.extraClasses = props.extraClasses || '';
        this.focusOnLoad = props.focusOnLoad || false;
        this.goto = props.goto || '';
    }

    didHover() {
        this.optionNode.focus()
    }

    componentDidMount() {
        if (this.focusOnLoad) {
            this.didHover()
        }
    }

    handleClick() {
        window.location.assign(this.goto);
    }

    render() {
        return (
            <div
                className={`option ${this.extraClasses}`}
                tabIndex="0"
                role="button"
                onMouseEnter={this.didHover.bind(this)}
                ref={(node) => { this.optionNode = node; }}
                onClick={this.handleClick.bind(this)}
                onKeyDown={({ keyCode = -1 }) => { if (keyCode === 13) this.handleClick() }}
            >
                <div className="heart-icon"><Heart color={this.heartColor} /></div>
                <span>{this.text}</span>
            </div>
        );
    }
}


const NotFound = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Space Dysco - 404</title>
                <meta
                    name="Description"
                    content="The content you requested was not found. How unfortunate."
                />
                <html lang="en" />
            </Helmet>
            <div className="not-found-container">
                <h1 className="not-found-title no-select">404</h1>
                <article className="not-found-text">
                    You cannot give up just yet…
                    <br></br>
                    <br></br>
                * (Rebelliously navigating to nothing fills you with determination.)
                </article>
                <div className="not-found-options-container no-select">
                    <Option heartColor="rgb(255, 0, 0)" text="Go home" focusOnLoad={true} goto="https://dysco.space"/>
                    <Option heartColor="rgb(27, 16, 248)" text="Flee" extraClasses="flee" goto="https://google.com"/>
                </div>
            </div>
        </>
    )
};

export default NotFound;
